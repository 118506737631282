.aiChat {
  width: 100%;
  overflow: hidden;
  max-height: var(--max-height);
  justify-content: space-between;
  align-self: stretch;
}

/* --max-height css variable contains the canvas height. and the canvas has some gutter padding on
  top and bottom So we need to subtract the gutter padding from the max-height to get the actual height of the widget,
  otherwise the widget will exceed the canvas height and it will show a scrollbar on canvas.
*/
.aiChat {
  --max-height: calc(var(--canvas-height) - (var(--outer-spacing-4) * 2));
}
/* Widgets like zone and section can toggle their visual separation. When the visual separation is turned on ( on either zone or section ),
  it adds a gutter padding around them. In order to account for the gutter top and bottom padding, we subtract 2x the gutter padding
  from the max-height so that the widget does not overflow just like how we did in the above case. Also, this case overrides the above case.

  So total height of widget = max-height - gutter padding of section/zone ( 2x --outer-spacing-3 ) - gutter padding of canvas ( --outer-spacing-4 )

  Note that the gutter padding for section and zone is same, so it does not matter if the visual separation is on zone or section.
*/
:is(
    [elevation="1"][data-elevation="true"],
    [elevation="2"][data-elevation="true"]
  )
  .aiChat {
  --max-height: calc(
    var(--canvas-height) - (var(--outer-spacing-4) * 2) -
      ((var(--outer-spacing-3) * 2))
  );
}

/** This is for the case when there is visual separation on both zone and section.
  In this case, we need to subtract 4x ( 2x top and 2x bottom since the gutter padding is on zone and section both)
  the gutter padding from the max-height so that the widget does not overflow. This case overrides the above two cases.

  So total height of widget = max-height - gutter padding of section - gutter padding of zone - gutter padding of canvas

  Since the gutter padding for section and zone is same, we use 4x the gutter padding ( 2x top + 2x bottom )
*/
[elevation="1"][data-elevation="true"]
  [elevation="2"][data-elevation="true"]
  .aiChat {
  --max-height: calc(
    var(--canvas-height) - (var(--outer-spacing-4) * 2) -
      ((var(--outer-spacing-3) * 4))
  );
}

.header {
  padding: var(--inner-spacing-2) var(--inner-spacing-4);
  border-bottom: 1px solid var(--color-bd-elevation-1);
  background-color: transparent;
}

.deleteButton {
  margin-left: auto;
}

.avatarImage {
  align-items: center;
  display: inline-flex;
  height: var(--icon-size-3);
  width: var(--icon-size-3);
  justify-content: center;
  flex-shrink: 0;
}

.disclaimer {
  color: var(--color-fg-neutral-subtle);
}

.disclaimer [data-icon] {
  align-self: self-start;
  flex-shrink: 0;
}

.messageList {
  overflow-y: auto;
}

.messageListItem:first-child {
  margin-block-start: auto;
}

.messageListItem:first-child {
  white-space: pre-wrap;
}

.messageListItem:first-child [data-markdown]::after,
.messageListItem:first-child [data-markdown]::before {
  content: unset;
}

.userMessage {
  background-color: var(--color-bg-neutral-subtle);
  padding: var(--inner-spacing-3) var(--inner-spacing-4) var(--inner-spacing-4)
    var(--inner-spacing-5);
  border-radius: var(--border-radius-elevation-3);
  white-space: pre-wrap;
}
